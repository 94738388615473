import BaseUI from "./BaseUI"

import nft_close from '@/game/assets/nft_close.png'

class NFTCloseUI extends BaseUI {
    constructor(sceneKey, zone, posX, posY, scaleNum, onCreate, onClick) {
        super(sceneKey, zone, posX, posY, scaleNum, onCreate)
        this.onClick = onClick
    }

    preload() {
        super.addElement("nft_close", BaseUI.Image, 0, 0, 1, { url: nft_close })
        super.setBG("nft_close")

        super.preload()
    }

    create() {
        super.create()

        super.addModuleClickEvent("nft_close", "pointerdown", this.onClick)
    }

    show() {
        super.setActive(true)
    }

    hide() {
        super.setActive(false)
    }
}

export default NFTCloseUI