import BaseUI from "./BaseUI"

import land_bg from '@/game/assets/dlg/land_bg.png'
import btn_close from '@/game/assets/dlg/btn_close.png'
import dlg_bg from '@/game/assets/dlg/dlg_bg.png'
import city_info from '@/game/assets/dlg/city_info.png'
import dfawef5 from '@/game/assets/dlg/dfawef5.png'

class LandTips extends BaseUI {
    constructor(sceneKey, zone, posX, posY, scaleNum, onCreate, onHide) {
        super(sceneKey, zone, posX, posY, scaleNum, onCreate)
        this.onHide = onHide
    }

    preload() {
        let scale = this.scaleNum

        super.addElement("dlg_bg", BaseUI.Image, 0, 0, 1, { url: dlg_bg, height: 315 })
        super.addElement("city_info", BaseUI.Image, 37 * scale, 300 * scale, 2, { url: city_info })
        super.addElement("land_bg", BaseUI.Image, 130 * scale, 47 * scale, 3, { url: land_bg })
        super.addElement("land_icon", BaseUI.Image, 152 * scale, 59 * scale, 4, { url: dfawef5, scaleX: 1, scaleY: 1 })
        super.addElement("btn_close", BaseUI.Image, 490 * scale, -35 * scale, 4, { url: btn_close })

        super.addElement("starText", BaseUI.Text, 60 * scale, 312 * scale, 5, { text: "Planet:", fontColor: '#00f1e8', fontSize: 26 })
        super.addElement("starConetentText", BaseUI.Text, 150 * scale, 312 * scale, 5, { text: "TITAN", fontColor: '#f0b432', fontSize: 26 })

        super.addElement("cityText", BaseUI.Text, 60 * scale, 387 * scale, 5, { text: "City:", fontColor: '#00f1e8', fontSize: 26 })
        super.addElement("cityConetentText", BaseUI.Text, 125 * scale, 387 * scale, 5, { text: "000001", fontColor: '#f0b432', fontSize: 26 })

        super.addElement("landCountText", BaseUI.Text, 60 * scale, 463 * scale, 5, { text: "Total Land:", fontColor: '#00f1e8', fontSize: 26 })
        super.addElement("landCountConetentText", BaseUI.Text, 200 * scale, 463 * scale, 5, { text: "150", fontColor: '#f0b432', fontSize: 26 })

        super.addElement("evalText", BaseUI.Text, 60 * scale, 535 * scale, 5, { text: "City Grade:", fontColor: '#00f1e8', fontSize: 26 })
        super.addElement("evalConetentText", BaseUI.Text, 60 * scale, 570 * scale, 5, { text: "First-class City reserved land", fontColor: '#f0b432', fontSize: 26 })

        super.setBG("dlg_bg")

        super.preload()
    }

    create() {
        super.create()

        super.setElementActive("land_icon", false)
        super.addModuleClickEvent("btn_close", "pointerup", () => { this.hide() })

        if (this.showData) {
            let data = this.showData
            this.show(data.starContent, data.cityContent, data.landContent, data.evalContent, data.landUrl)
        }
    }

    show(starContent, cityContent, landContent, evalContent, landUrl) {
        if (!this.loaded) {
            this.showData = {
                starContent: starContent,
                cityContent: cityContent,
                landContent: landContent,
                evalContent: evalContent,
                landUrl: landUrl
            }
            return
        }

        super.setElementActive("land_icon", true)
        super.setActive(true)

        super.setText("starConetentText", starContent)
        super.setText("cityConetentText", cityContent)
        super.setText("landCountConetentText", landContent)
        super.setText("evalConetentText", evalContent)
        // super.setTexture("land_icon", landUrl, true)

        if (this.showData)
            delete this.showData
    }

    hide() {
        super.setActive(false)

        if (this.onHide) {
            this.onHide(this)
        }
    }
}

export default LandTips