class BaseUI extends Phaser.Scene {
    constructor(sceneKey, zone, posX, posY, scaleNum, onCreate) {
        super({ 'key': sceneKey })
        this.posX = posX
        this.posY = posY
        this.zone = zone
        this.isDestroyed = false
        this.scaleNum = scaleNum
        this.loaded = false
        this.onCreate = onCreate
        this.modules = {}
    }

    addElement(key, type, offsetX, offsetY, depth, datas) {
        this.modules[key] = { key: key, offsetX: offsetX, offsetY: offsetY, depth: depth, type: type, datas: datas || {} }
    }

    setText(key, text) {
        if (this.isDestroyed) return

        let module = this.modules[key]
        if (module && module.type == BaseUI.Text) {
            module.com.setText(text)
        }
    }

    setTexture(key, url, isLoadTexHide) {
        if (this.isDestroyed) return

        let module = this.modules[key]
        if (module && module.type == BaseUI.Image) {
            if (isLoadTexHide) {
                module.com.setVisible(false)
            }

            this.load.once("complete", () => {
                module.com.setTexture(url)
                module.com.setVisible(this.activeState)
            }, this)
            this.load.image(url, url)
            this.load.start()
        }
    }

    preload() {
        for (let key in this.modules) {
            let module = this.modules[key]
            if (module.type == BaseUI.Image)
                this.load.image(module.datas.url, module.datas.url)
        }
    }

    create() {
        this.cameras.main.setViewport(0, 0, window.gameWidth, window.gameHeight)

        for (let key in this.modules) {
            let module = this.modules[key]
            let com = null
            let datas = module.datas
            switch (module.type) {
                case BaseUI.Image:
                    com = this.add.image(this.posX + module.offsetX, this.posY + module.offsetY, datas.url).setOrigin(0)
                    break
                case BaseUI.Text:
                    com = this.add.text(this.posX + module.offsetX, this.posY + module.offsetY, datas.text || '', { fontFamily: datas.font || 'Arial', fontSize: datas.fontSize || 20, color: datas.fontColor || '#0000FF' }).setOrigin(0)
                    break
                default:
                    continue
            }
            com.setScale(this.scaleNum)
            com.depth = module.depth

            if (datas.width) {
                com.displayWidth = datas.width
            }

            if (datas.height) {
                com.displayHeight = datas.height
            }

            if (datas.scaleX) {
                com.scaleX = this.scaleNum * datas.scaleX
            }

            if (datas.scaleY) {
                com.scaleY = this.scaleNum * datas.scaleY
            }

            module.com = com
        }

        this.loaded = true

        this.setBG(this.bgName)

        if (this.onCreate) {
            this.onCreate(this)
            delete this.onCreate
        }
    }

    setBG(name) {
        if (!name) return

        let m = this.getElement(name)
        if (m && m.com) {
            this.bg = m.com

            if (this.bgName) delete this.bgName
        } else {
            this.bgName = name
        }
    }

    setPos(posX, posY) {
        if (this.isDestroyed) return

        this.posX = posX
        this.posY = posY

        for (let key in this.modules) {
            let module = this.modules[key]
            if (module.com) {
                module.com.setPosition(this.posX + module.offsetX, this.posY + module.offsetY, 0, 0)
            }
        }
    }

    addModuleClickEvent(key, eventName, func) {
        if (this.isDestroyed) return

        let module = this.modules[key]
        if (module && module.com) {
            module.com.setInteractive().on(eventName, func)
        }
    }

    removeModuleClickEvent(key) {
        if (this.isDestroyed) return

        let module = this.modules[key]
        if (module && module.com) {
            module.com.removeInteractive()
        }
    }

    setElementPos(key, offsetX, offsetY) {
        if (this.isDestroyed) return

        let module = this.modules[key]
        if (module) {
            module.offsetX = offsetX
            module.offsetY = offsetY

            if (module.com) {
                module.com.setPosition(this.posX + module.offsetX, this.posY + module.offsetY, 0, 0)
            }
        }
    }

    getElement(key) {
        return this.modules[key]
    }

    setElementActive(key, state) {
        if (this.isDestroyed) return

        let module = this.modules[key]
        if (module && module.com) {
            module.com.setVisible(state)
        }
    }

    setActive(state) {
        this.activeState = state
        if (this.isDestroyed) return

        for (let key in this.modules) {
            let module = this.modules[key]
            if (module.com) {
                module.com.setVisible(state)
            }
        }
    }

    destroy() {
        if (this.isDestroyed) return
        this.isDestroyed = true

        for (let key in this.modules) {
            let module = this.modules[key]
            if (module.com) {
                module.com.destroy(true)
                module.com = null
            }
        }
    }

    setAlignPos(type, offsetX, offsetY) {
        if (!this.bg) return
        let sWidth = window.gameWidth
        let sHeight = window.gameHeight
        let cWidth = this.bg.displayWidth
        let cHeight = this.bg.displayHeight

        offsetX = offsetX || 0
        offsetY = offsetY || 0

        if (type == "h") {
            this.setPos((sWidth - cWidth) / 2 + offsetX, offsetY)
        } else if (type == "v") {
            this.setPos(offsetX, (sHeight - cHeight) / 2 + offsetY)
        } else if (type == "mb") {
            this.setPos((sWidth - cWidth) / 2 + offsetX, sHeight - cHeight + offsetY)
        } else if (type == "rt") {
            this.setPos(sWidth - cWidth + offsetX, offsetY)
        }
        else {
            this.setPos((sWidth - cWidth) / 2 + offsetX, (sHeight - cHeight) / 2 + offsetY)
        }
    }

    refresh() {
        this.cameras.main.setPosition(0, 0)
        this.zone.bringToTop()
    }
}

BaseUI.create = function (master, uiModule, sceneKey, ...args) {
    let sWidth = window.gameWidth
    let sHeight = window.gameHeight

    let zone = master.add.zone(0, 0, sWidth, sHeight).setOrigin(0)
    let scene = new uiModule(sceneKey, zone, ...args)
    master.scene.add(sceneKey, scene, true)
    return scene
}

BaseUI.remove = function (master, sceneKey) {
    if (master.scene.get(sceneKey)) {
        master.scene.remove(sceneKey)
    }
}

BaseUI.Image = "image"
BaseUI.Text = "text"

BaseUI.CanvasWidth = 1920
BaseUI.CanvasHeight = 1080

BaseUI.WidthRatio = window.gameWidth / BaseUI.CanvasWidth
BaseUI.HeightRatio = window.gameHeight / BaseUI.CanvasHeight

export default BaseUI