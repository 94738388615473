import { Scene } from 'phaser'

import civ_color from '@/game/assets/civ_color.png'
import my_civ_json from '@/game/assets/my_civ.json'

import des_png from '@/game/assets/tmw_desert_spacing.png'
import des_json from '@/game/assets/desert.json'

import uv_grid_diag from '@/game/assets/uv-grid-diag.png'
import block from '@/game/assets/block.png'

export default class PlayScene extends Scene {
  constructor () {
    super({ key: 'PlayScene' })
  }

  preload () {
    console.log("PlayScene.preload !!!")
    this.load.image('civ_color', civ_color);
    this.load.tilemapTiledJSON('my_civ', my_civ_json);

    this.load.image('des_png', des_png);
    // this.load.tilemapTiledJSON('map', des_json);

    this.load.image('bg', uv_grid_diag);
    this.load.image('block', block);

  }

  create () {
    console.log('PlayScene.create!!');
    this.cam = this.cameras.main;
    // let bg = this.add.image(0, 0, 'bg')

    this.cameras.main.setBounds(-1024, -1024, 1024 * 2, 1024 * 2);
    this.physics.world.setBounds(-1024, -1024, 1024 * 2, 1024 * 2);

    this.add.image(-1024, -1024, 'bg').setOrigin(0);
    this.add.image(0, -1024, 'bg').setOrigin(0);
    this.add.image(-1024, 0, 'bg').setOrigin(0);
    this.add.image(0, 0, 'bg').setOrigin(0);

    this.cursors = this.input.keyboard.createCursorKeys();

    this.player = this.physics.add.image(0, 0, 'block');

    this.player.setCollideWorldBounds(true);

    this.cameras.main.startFollow(this.player, true);

    this.text = this.add.text(32, 32).setScrollFactor(0).setFontSize(32).setColor('#ffffff');

    let that = this;
    this.isMouseDown = false;
    that.registry.events.on('changedata', that.changeData, that)
    this.registry.set("sceneName", {"name":"PlayScene"})

    this.game.canvas.onmousedown = function (e) {
      that.isMouseDown = true;
      that.registry.set("selectTown", {'sss': 33, "aa":'xxx'})
      
    };

    this.game.canvas.onmouseup = function (e) {
      that.isMouseDown = false;
    };


    this.game.canvas.onmousemove = function (e) {
        var mouseX = e.clientX - that.game.canvas.offsetLeft;
        var mouseY = e.clientY - that.game.canvas.offsetTop;
        if (that.isMouseDown) {
          that.cam.scrollX -= e.movementX;
          that.cam.scrollY -= e.movementY;
        }
    };

    this.game.canvas.onmouseleave = function (e) {
      that.isMouseDown = false;
    };

    this.game.canvas.onwheel = function (e) {
      if (e.deltaY > 0) {

        if (that.cam.zoom > 0.02) {
          that.cam.zoom -= 0.01;
        }

      } else if (e.deltaY < 0) {

        if (that.cam.zoom < 2.0) {
          that.cam.zoom += 0.01;
        }

      }


    }
    
  }

  update () {
    var cam = this.cameras.main;


    this.text.setText([
        'ScrollX: ' + cam.scrollX,
        'ScrollY: ' + cam.scrollY,
        'MidX: ' + cam.midPoint.x,
        'MidY: ' + cam.midPoint.y
    ]);

    this.player.setVelocity(0);

    if (this.cursors.left.isDown)
    {
        this.player.setVelocityX(-300);
    }
    else if (this.cursors.right.isDown)
    {
        this.player.setVelocityX(300);
    }

    if (this.cursors.up.isDown)
    {
        this.player.setVelocityY(-300);
    }
    else if (this.cursors.down.isDown)
    {
        this.player.setVelocityY(300);
    }

  }

  move(pointer, x, y, isDown) {

    // mouseBody.body.x = x;
    // mouseBody.body.y = y;
    // line.setTo(cow.x, cow.y, mouseBody.x, mouseBody.y);
    console.log("move:", pointer, x, y, isDown);

  }

  changeData(parent, key, data) {
    // console.log("xxx1 changeData ==>", parent, key, data)
    // if (key == 'sceneName')  {
    //   console.log("xxx2 changeData ==>", parent, key, data)
    // }
  }

};

