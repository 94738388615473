import BaseUI from "./BaseUI"

import land_bg from '@/game/assets/dlg/land_bg.png'
import btn_close from '@/game/assets/dlg/btn_close.png'
import dlg_bg from '@/game/assets/dlg/dlg_bg.png'
import land_info from '@/game/assets/dlg/land_info.png'

class CityTips extends BaseUI {
    constructor(sceneKey, zone, posX, posY, scaleNum, onCreate, onHide) {
        super(sceneKey, zone, posX, posY, scaleNum, onCreate)
        this.onHide = onHide
    }

    preload() {
        let scale = this.scaleNum

        super.addElement("dlg_bg", BaseUI.Image, 0, 0, 1, { url: dlg_bg, height: 437 })
        super.addElement("land_info", BaseUI.Image, 37 * scale, 300 * scale, 2, { url: land_info })
        super.addElement("land_bg", BaseUI.Image, 130 * scale, 47 * scale, 3, { url: land_bg })
        super.addElement("land_icon", BaseUI.Image, 173 * scale, 59 * scale, 4, { url: land_bg, scaleX: 0.33, scaleY: 0.33 })
        super.addElement("btn_close", BaseUI.Image, 490 * scale, -35 * scale, 4, { url: btn_close })

        super.addElement("starText", BaseUI.Text, 60 * scale, 313 * scale, 5, { text: "Planet:", fontColor: '#00f1e8', fontSize: 26 })
        super.addElement("starConetentText", BaseUI.Text, 150 * scale, 313 * scale, 5, { text: "TITAN", fontColor: '#f0b432', fontSize: 26 })

        super.addElement("nameText", BaseUI.Text, 60 * scale, 388 * scale, 5, { text: "Name:", fontColor: '#00f1e8', fontSize: 26 })
        super.addElement("nameConetentText", BaseUI.Text, 150 * scale, 388 * scale, 5, { text: "xxxxx", fontColor: '#f0b432', fontSize: 26 })

        super.addElement("infoText", BaseUI.Text, 60 * scale, 465 * scale, 5, { text: "Info:", fontColor: '#00f1e8', fontSize: 26 })
        super.addElement("infoConetentText", BaseUI.Text, 125 * scale, 465 * scale, 5, { text: "xxxxx", fontColor: '#f0b432', fontSize: 26 })

        super.addElement("cityLevelText", BaseUI.Text, 60 * scale, 543 * scale, 5, { text: "City Grade:", fontColor: '#00f1e8', fontSize: 26 })
        super.addElement("cityLevelConetentText", BaseUI.Text, 210 * scale, 543 * scale, 5, { text: "xxxxx", fontColor: '#f0b432', fontSize: 26 })

        super.addElement("landNumberText", BaseUI.Text, 60 * scale, 620 * scale, 5, { text: "Land No.:", fontColor: '#00f1e8', fontSize: 26 })
        super.addElement("landNumberConetentText", BaseUI.Text, 190 * scale, 620 * scale, 5, { text: "012345678", fontColor: '#f0b432', fontSize: 26 })

        super.addElement("evalText", BaseUI.Text, 60 * scale, 700 * scale, 5, { text: "Land Grade:", fontColor: '#00f1e8', fontSize: 26 })
        super.addElement("evalConetentText", BaseUI.Text, 220 * scale, 700 * scale, 5, { text: "xxxxx", fontColor: '#f0b432', fontSize: 26 })

        super.addElement("landPosText", BaseUI.Text, 60 * scale, 780 * scale, 5, { text: "Coordinate:", fontColor: '#00f1e8', fontSize: 26 })
        super.addElement("landPosConetentText", BaseUI.Text, 205 * scale, 780 * scale, 5, { text: "x:31/y:123", fontColor: '#f0b432', fontSize: 26 })

        super.setBG("dlg_bg")

        super.preload()
    }

    create() {
        super.create()

        super.setElementActive("land_icon", false)
        super.addModuleClickEvent("btn_close", "pointerup", () => { this.hide() })

        if (this.showData) {
            let data = this.showData
            this.show(data.starContent, data.name, data.infoConetent, data.cityLevelConetent, data.landNumberConetent, data.evalConetent, data.landPosConetent, data.landUrl)
        }
    }

    show(starContent, name, infoConetent, cityLevelConetent, landNumberConetent, evalConetent, landPosConetent, landUrl) {
        if (!this.loaded) {
            this.showData = {
                starContent: starContent,
                name: name,
                infoConetent: infoConetent,
                cityLevelConetent: cityLevelConetent,
                landNumberConetent: landNumberConetent,
                evalConetent: evalConetent,
                landPosConetent: landPosConetent,
                landUrl: landUrl
            }
            return
        }

        super.setElementActive("land_icon", true)
        super.setActive(true)

        super.setText("starConetentText", starContent)
        super.setText("nameConetentText", name)
        super.setText("infoConetentText", infoConetent)
        super.setText("cityLevelConetentText", cityLevelConetent)
        super.setText("landNumberConetentText", landNumberConetent)
        super.setText("evalConetentText", evalConetent)
        super.setText("landPosConetentText", landPosConetent)
        super.setTexture("land_icon", landUrl, true)

        if (this.showData)
            delete this.showData
    }

    hide() {
        super.setActive(false)

        if (this.onHide) {
            this.onHide(this)
        }
    }
}

export default CityTips