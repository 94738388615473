import BaseUI from "./BaseUI"

import nft_start from '@/game/assets/nft_start.png'

class NFTStarUI extends BaseUI {
    constructor(sceneKey, zone, posX, posY, scaleNum, onCreate, onClick) {
        super(sceneKey, zone, posX, posY, scaleNum, onCreate)
        this.onClick = onClick
    }

    preload() {
        super.addElement("nft_start", BaseUI.Image, 0, 0, 1, { url: nft_start })
        super.setBG("nft_start")

        super.preload()
    }

    create() {
        super.create()


        super.getElement("nft_start").com.alpha = 0.0001
        super.addModuleClickEvent("nft_start", "pointerdown", this.onClick)
    }

    show() {
        super.setActive(true)
    }

    hide() {
        super.setActive(false)
    }
}

export default NFTStarUI