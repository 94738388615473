import { Scene } from 'phaser'

import TownScene from '@/game/scenes/TownScene'


import color75 from '@/game/assets/world/color75.png'
import world_json from '@/game/assets/world/world.json'
import city_info_json from '@/game/assets/city.json'
import world_town_json from '@/game/assets/map.json'
import adrlist_json from '@/game/assets/tkn/adrlist.json'
import cityname_json from '@/game/assets/cityname.json'
import des_png from '@/game/assets/tmw_desert_spacing.png'
import black from '@/game/assets/black.png'

// 弹窗的资源
import BaseUI from './ui/BaseUI';
import LandTips from './ui/LandTips';
import CityLandNavi from './ui/CityLandNavi'
import MapZoomUI from './ui/MapZoomUI';
import HomeUI from './ui/HomeUI';

import btn_center_buy from '@/game/assets/btn_center_buy.png'
import NFTStarUI from './ui/NFTStarUI';
import NFTCloseUI from './ui/NFTCloseUI';

export default class WorldScene extends Scene {
  constructor() {
    super({ key: 'WorldScene' })
    this.count = 0;
    this.initFlag = true;
    this.fitZoom = 0.1;
    this.marker = null;
    this.selectX = 0;
    this.selectY = 0;
    this.townLayer = null;
    this.cityLayer = null;

    this.townLayers = [];
    this.cityLayers = [];

    this.worldTowns = {};
    this.belongTowns = {};
    this.townWin = null;
    this.UIWin = null;
    this.cityTips = null;
    this.cityInfo = {};


    this.oneEarthWidth = 1300
    this.oneEarthHeight = 750
    this.rowEarthCount = 5
    this.colEarthConut = 5
    this.totalEarthCount = this.rowEarthCount * this.colEarthConut
    this.totalEarthWidth = this.oneEarthWidth * this.colEarthConut
    this.totalEarthHeight = this.oneEarthHeight * this.rowEarthCount

    this.tknID = this.getQueryVariable("addr") || ""
  }

  getQueryVariable(name) {
    const reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
    const result = window.location.search.substring(1).match(reg);
    if (result != null) {
      return decodeURI(result[2]);
    } else {
      return null;
    }
  }

  preload() {
    console.log("WorldScene.preload !!!")

    this.load.image('color75', color75);
    this.load.image('black', black);
    this.load.tilemapTiledJSON('world', world_json);
    this.load.image('des_png', des_png);
    this.load.json('world_town', world_town_json);
    this.load.json('city_json', city_info_json);
    this.load.json('adrlist_json', adrlist_json);
    this.load.json('cityname_json', cityname_json)

    this.load.image('btn_center_buy', btn_center_buy);

    for (let i = 1; i <= this.totalEarthCount; i++) {
      this.load.image('civ_bg_' + i, require('@/game/assets/earth1/images/civ_bg_' + i + '.png'))
    }
    console.log("WorldScene.preload finish!!!")
  }

  getBuyData() {
    let adrListJson = this.cache.json.get('adrlist_json')
    let arr = adrListJson["addr-list"]
    for (let i = 0; i < arr.length; i++) {
      let data = arr[i]
      if (data.addr == this.tknID) {
        return data["token-list"]
      }
    }

    return []
  }

  createSelectLandScrollView() {
    let data = this.getBuyData()

    let startX = -1000000
    let startY = -1000000

    let displayWidth = 150
    let displayHeight = 220

    let oneContentHeight = 50

    let contentHeight = Math.ceil(data.length / 2) * oneContentHeight - displayHeight
    if(contentHeight < 0) contentHeight = 0

    let cam = this.cameras.add(window.gameWidth - displayWidth - 21.5, 100, displayWidth, displayHeight)
    cam.scrollX = startX
    cam.scrollY = startY
    let scrollBG = this.add.image(startX, startY - 1000, "black").setOrigin(0).setInteractive()
    scrollBG.setScale(200, 10000)

    this.input.setDraggable(scrollBG)

    this.input.dragDistanceThreshold = 10;

    let that = this

    for (let i = 0; i < data.length; i++) {
      let btn = this.add.text(startX + 17 + (i % 2) * 70, startY + 10 + Math.floor(i / 2) * oneContentHeight, data[i], { fontFamily: 'Arial', fontSize: 20, color: '#FFFFFF' }).setOrigin(0).setInteractive()
      this.input.setDraggable(btn)
      btn.on('pointerup', function (pointer) {
        if (that.hasScrollViewDrag) {
          that.hasScrollViewDrag = false
          return
        }
        console.log("======select scroll view", data[i]) //TODO
      });
    }

    this.input.on('dragstart', function (pointer, gameObject, dragX, dragY) {
      that.scrollBGDragY = 0
      that.scrollViewDrag = true
      that.hasScrollViewDrag = true
    });

    this.input.on('drag', function (pointer, gameObject, dragX, dragY) {
      if (that.townWin) {
        return
      }
      if (that.cityTipsShowFlag) {
        return
      }

      if (that.scrollBGDragY == 0) {
        that.scrollBGDragY = 1
        return
      }

      let camScrollY = cam.scrollY - that.moveDeltaY
      if (camScrollY < startY) {
        camScrollY = startY
      }
      if (camScrollY > startY + contentHeight) {
        camScrollY = startY + contentHeight
      }

      cam.scrollY = camScrollY
    });

    this.input.on('dragend', function (pointer, gameObject) {
      that.scrollViewDrag = false
    });
    return cam
  }

  create() {
    console.log('WorldScene.create!!');
    this.cam = this.cameras.main;

    this.world = this.make.tilemap({ key: 'world' });

    var civ_color = this.world.addTilesetImage('color75', 'color75');

    this.cityTipsShowFlag = false

    this.landScrollView = this.createSelectLandScrollView()
    this.landScrollView.setVisible(false)

    for (let x = 0; x < this.colEarthConut; x++) {
      for (let y = 0; y < this.rowEarthCount; y++) {
        let index = x * this.colEarthConut + y + 1;
        // console.log("==========> index = ", index)
        let earth = this.add.image(0, 0, "civ_bg_" + index).setOrigin(0)
        earth.setPosition(y * this.oneEarthWidth, (x * this.oneEarthHeight))
      }
    }

    this.homeUI = BaseUI.create(this, HomeUI, "homeUI", 0, 0, 0.35, (self) => {
      self.setAlignPos("rt", -20, 18)
    }, () => {
      window.open("https://metacitym.com/");
    })

    this.nftCloseUI = BaseUI.create(this, NFTCloseUI, "nftCloseUI", 0, 0, 0.35, (self) => {
      self.setAlignPos("rt", -20, 60)
    }, () => {
    })

    this.nftStartUI = BaseUI.create(this, NFTStarUI, "nftStartUI", 0, 0, 0.35, (self) => {
      self.setAlignPos("rt", -20, 60)
    }, () => {
      if (!this.nftStartUI || this.cityTipsShowFlag || this.townWin) return
      let com = this.nftStartUI.getElement("nft_start").com
      if (com.alpha == 1) {
        com.alpha = 0.0001
        this.landScrollView.setVisible(false)
      }
      else {
        com.alpha = 1
        this.landScrollView.setVisible(true)
      }
    })


    this.add.image(window.gameWidth, window.gameHeight, "black").setOrigin(0).setInteractive()

    let cityJson = this.cache.json.get('city_json');
    if (cityJson && cityJson.citylist) {
      let cityList = cityJson.citylist;
      for (let i = 0; i < cityList.length; i++) {
        let city = cityList[i];
        this.cityInfo[city.id] = city;
      }
    }
    console.log("this.cityInfo =", this.cityInfo);

    //TODO: 设个地方设计得不好，后续看什么优化
    let towns = ['TownAsia204', 'TownAfrica154', 'TownSouthAmerica88', 'TownEurope20', 'TownOceania44', 'TownNorthAmerica180'];
    let citys = ['CityAsia18', 'CityAfrica3', 'CitySouthAmerica4', 'CityEurope18', 'CityOceania2', 'CityNorthAmerica10']

    this.townLayers = [];
    this.cityLayers = [];
    for (let i = 0; i < towns.length; i++) {
      this.townLayers[i] = this.world.createLayer(towns[i], civ_color, 0, 0);
    }

    for (let i = 0; i < citys.length; i++) {
      this.cityLayers[i] = this.world.createLayer(citys[i], civ_color, 0, 0);
    }

    this.townLayer = this.townLayers[0]; //this.world.createLayer('TownAsia204', civ_color, 0, 0);
    this.cityLayer = this.cityLayers[0]; //this.world.createLayer('CityAsia18', civ_color, 0, 0);

    console.log("townLayers =", this.townLayers);
    console.log("cityLayers =", this.cityLayers);

    this.marker = this.add.graphics();
    this.marker.lineStyle(3, 0xef5b9c, 2);
    this.marker.strokeRect(0, 0, this.world.tileWidth, this.world.tileHeight);


    let that = this;
    this.isMouseDown = false;
    that.registry.events.on('changedata', that.changeData, that)
    this.registry.set("sceneName", { "name": "WorldScene" })
    this.events.on('towngoback', this.towngoback, this);
    this.events.on('switchNavi', this.switchNavi, this);
    this.events.on('mapZoomAdd', this.mapZoomAdd, this);
    this.events.on('mapZoomDel', this.mapZoomDel, this);
    this.events.on('swithTown', this.swithTown, this);

    let w = this.game.canvas.width / this.totalEarthWidth
    let h = this.game.canvas.height / this.totalEarthHeight
    if (w < 1 || h < 1) {
      this.fitZoom = w < h ? h : w;
    }

    that.cam.scrollX += (this.totalEarthWidth - this.game.canvas.width) / 2;
    that.cam.scrollY += (this.totalEarthHeight - this.game.canvas.height) / 2;

    //检验一下数据是否正确
    let world_town = this.cache.json.get('world_town');

    this.worldTowns = {}
    this.belongTowns = {}

    if (world_town.townlist) {
      for (let i = 0; i < world_town.townlist.length; i++) {
        let townInfo = world_town.townlist[i];
        let tile = this.getTownTileByXY(townInfo.posx, townInfo.posy);
        if (tile) {
          // console.log('i, data, town tile = ', i, townInfo, tile);
        } else {
          tile = this.getCityTileByXY(townInfo.posx, townInfo.posy);
          if (tile) {
            // console.log('i, data, city tile = ', i, townInfo, tile);
          } else {
            console.log('!!!!! i, data, not found tile !!!!', i, townInfo, tile);
          }
        }
        let key = 'x_' + townInfo.posx + '_y_' + townInfo.posy;
        let tmp = []
        if (this.worldTowns[key]) {
          tmp = this.worldTowns[key];
        }
        tmp[tmp.length] = townInfo
        this.worldTowns[key] = tmp

        let belongTmp = []
        if (this.belongTowns[townInfo.belong]) {
          belongTmp = this.belongTowns[townInfo.belong]
        }
        belongTmp[belongTmp.length] = townInfo
        this.belongTowns[townInfo.belong] = belongTmp
      }
    }

    console.log("this.worldTowns =", this.worldTowns);
    console.log("this.belongTowns =", this.belongTowns);

    window.NaviWin = BaseUI.create(this, CityLandNavi, "CityLandNavi", 0, 0, 0.4, (self) => {
      if (this.game.device.os.desktop) {
        self.setAlignPos('h', 0, 20)
      } else {
        self.setAlignPos("mb", 0, -20)
      }
    });

    // this.zoomWin = BaseUI.create(this, MapZoomUI, "MapZoomUI", window.gameWidth - 64, window.gameHeight - 133, 0.4);

    this.setConvasOnEvent();


    this.tweens.add({
      targets: that.cam,
      zoom: that.fitZoom,
      duration: 500,
      ease: 'Linear',
      onComplete: (twn, params) => {
        that.initFlag = false;
      },
    });
  }

  limitCameraZoom() {
    if (this.cam.zoom < 0.02) {
      this.cam.zoom = 0.02
    } else if (this.cam.zoom > 2) {
      this.cam.zoom = 2
    }
  }

  setConvasOnEvent() {
    let that = this;

    this.game.canvas.onmousedown = function (e) {
      that.isMouseDown = true;
      that.moveMainCamNextFrame = true
    };

    this.game.canvas.onmouseup = function (e) {
      that.isMouseDown = false;
    };

    this.game.canvas.ontouchstart = function (e) {
      if (e.touches.length == 1) {
        let touchData = e.touches[0]
        that.lastTouchX = touchData.clientX
        that.lastTouchY = touchData.clientY

        that.touchZoom = false
      }
      else if (e.touches.length == 2) {
        let touch1Data = e.touches[0]
        let touch2Data = e.touches[1]

        that.lastTouch1X = touch1Data.clientX
        that.lastTouch1Y = touch1Data.clientY

        that.lastTouch2X = touch2Data.clientX
        that.lastTouch2Y = touch2Data.clientY

        that.touchZoom = true
      }

      that.moveMainCamNextFrame = true
    }

    this.game.canvas.ontouchmove = function (e) {
      if (that.moveMainCamNextFrame) {
        that.moveMainCamNextFrame = false
        return
      }
      if (that.cityTipsShowFlag) return
      if (e.touches.length == 1) {
        let touchData = e.touches[0]
        let newTouchX = touchData.clientX
        let newTouchY = touchData.clientY

        if (!that.touchZoom && !that.scrollViewDrag) {
          that.cam.scrollX -= ((newTouchX - that.lastTouchX) / that.cam.zoom);
          that.cam.scrollY -= ((newTouchY - that.lastTouchY) / that.cam.zoom);
        }

        that.moveDeltaX = newTouchX - that.lastTouchX
        that.moveDeltaY = newTouchY - that.lastTouchY

        that.lastTouchX = newTouchX
        that.lastTouchY = newTouchY

        that.touchZoom = false
      } else if (e.touches.length == 2) {
        let touch1Data = e.touches[0]
        let touch2Data = e.touches[1]
        let newTouch1X = touch1Data.clientX
        let newTouch1Y = touch1Data.clientY
        let newTouch2X = touch2Data.clientX
        let newTouch2Y = touch2Data.clientY

        let newDist = Math.sqrt(Math.pow(newTouch1X - newTouch2X, 2) + Math.pow(newTouch1Y - newTouch2Y, 2))
        let oldDist = Math.sqrt(Math.pow(that.lastTouch1X - that.lastTouch2X, 2) + Math.pow(that.lastTouch1Y - that.lastTouch2Y, 2))

        that.cam.zoom += (newDist - oldDist) / (window.gameWidth / 1080 * 1000)
        that.limitCameraZoom()

        that.lastTouch1X = newTouch1X
        that.lastTouch1Y = newTouch1Y

        that.lastTouch2X = newTouch2X
        that.lastTouch2Y = newTouch2Y

        that.touchZoom = true
      }
    }

    this.game.canvas.onmousemove = function (e) {
      if (that.moveMainCamNextFrame) {
        that.moveMainCamNextFrame = false
        return
      }
      if (that.cityTipsShowFlag) return
      if (that.isMouseDown && !that.scrollViewDrag) {
        that.cam.scrollX -= (e.movementX / that.cam.zoom);
        that.cam.scrollY -= (e.movementY / that.cam.zoom);
      }
      that.moveDeltaX = e.movementX
      that.moveDeltaY = e.movementY
      that.selectX = 0;
      that.selectY = 0;
    };

    this.game.canvas.onmouseleave = function (e) {
      that.isMouseDown = false;
      that.selectX = 0;
      that.selectY = 0;
    };

    this.game.canvas.onwheel = function (e) {
      if (that.cityTipsShowFlag) return

      if (e.deltaY > 0) {
        that.cam.zoom -= 0.01;
      } else if (e.deltaY < 0) {
        that.cam.zoom += 0.01;
      }

      that.limitCameraZoom()

      that.selectX = 0;
      that.selectY = 0;

    }
  }

  getTownTileByXY(posx, posy) {
    for (let i = 0; i < this.townLayers.length; i++) {
      let tLayer = this.townLayers[i]
      let tile = this.world.getTileAt(posx, posy, false, tLayer);
      if (tile) {
        return tile
      }
    }
    return null
  }

  getCityTileByXY(posx, posy) {
    for (let i = 0; i < this.cityLayers.length; i++) {
      let tLayer = this.cityLayers[i]
      let tile = this.world.getTileAt(posx, posy, false, tLayer);
      if (tile) {
        return tile
      }
    }
    return null
  }

  mapZoomAdd() {
    let that = this;
    that.cam.zoom += 0.01;
    that.limitCameraZoom()
    that.selectX = 0;
    that.selectY = 0;
  }

  mapZoomDel() {
    let that = this;
    that.cam.zoom -= 0.01;
    that.limitCameraZoom()
    that.selectX = 0;
    that.selectY = 0;
  }

  pad000(num, n) {
    return Array(n > num ? (n - ('' + num).length + 1) : 0).join(0) + num;
  }

  update() {
    if (this.initFlag) {
      return
    }
    if (this.townWin) {
      return
    }
    if (this.cityTipsShowFlag) {
      return
    }

    var worldPoint = this.input.activePointer.positionToCamera(this.cam);

    // Rounds down to nearest tile
    var pointerTileX = this.world.worldToTileX(worldPoint.x);
    var pointerTileY = this.world.worldToTileY(worldPoint.y);

    let tile = null;

    if (this.townLayer.visible == false) {
      tile = this.getCityTileByXY(pointerTileX, pointerTileY); //this.world.getTileAt(pointerTileX, pointerTileY, false, this.townLayer);
    } else {
      tile = this.getTownTileByXY(pointerTileX, pointerTileY); //this.world.getTileAt(pointerTileX, pointerTileY, false, this.townLayer);
      if (!tile) {
        tile = this.getCityTileByXY(pointerTileX, pointerTileY);
      }
    }
    if (!tile) {
      // console.log("this.townLayer.visible", this.townLayer.visible)
      return;
    }
    if (this.townLayer.visible == false) {
      if (this.input.manager.activePointer.isDown) {
        this.selectX = tile.x;
        this.selectY = tile.y;
      } else {
        if (tile.x == this.selectX && tile.y == this.selectY) {
          this.selectX = 0
          this.selectY = 0
          if (!this.cityTips) {
            this.cityTips = BaseUI.create(this, LandTips, "CityTips", 300, 150, 0.5, (self) => { self.setAlignPos() }, () => {
              window.NaviWin.show()
              this.time.delayedCall(300, () => {
                this.cityTipsShowFlag = false
              })
            });
          }
          let key = 'x_' + tile.x + '_y_' + tile.y;
          let townInfo = this.worldTowns[key]
          // TODO: 现在只有 Titan
          let starName = 'Titan';
          let cityId = '';
          let landNum = 150 * 4;  // 4个镇构成一个一级城市
          let cityLevel = '1st class city';  //一級城市 First class city /1st class city ; 二級城市. Second class city/2nd class city
          if (townInfo) {
            console.log("townInfo =", townInfo);
            let city = this.cityInfo[townInfo[0].belong]
            console.log("city = ", city, townInfo)
            if (city) {
              cityId = city.id
              if (city.level == 1) {
                cityLevel = '1st class city';
              } else {
                cityLevel = '2nd class city';
              }
            }
          }
          this.cityTipsShowFlag = true
          this.cityTips.show(starName, this.getCityName(cityId), landNum, cityLevel)
          window.NaviWin.hide()
        }
      }
      return;
    }

    this.marker.x = this.world.tileToWorldX(pointerTileX);
    this.marker.y = this.world.tileToWorldY(pointerTileY);

    if (this.input.manager.activePointer.isDown) {
      this.selectX = tile.x;
      this.selectY = tile.y;

    } else {
      if (tile.x == this.selectX && tile.y == this.selectY) {
        let key = 'x_' + tile.x + '_y_' + tile.y;
        let townInfo = this.worldTowns[key]
        if (townInfo) {
          let tmpTown = townInfo[0]
          let townList = this.belongTowns[tmpTown.belong]
          let mTown = {
            'townList': townList,
            'cityLevel': '2nd class city',
            'startIndex': 1,
          }
          this.selectX = 0;
          this.selectY = 0;
          let city = this.cityInfo[tmpTown.belong]
          if (city) {
            if (city.level == 1) {
              mTown.cityLevel = '1st class city';
            } else {
              mTown.cityLevel = '2nd class city';
            }
          }
          mTown.isTween = true;
          console.log("xxxxxxxxxxxxxxxxxxxxxxxx", tile, mTown);
          this.registry.set("selectTown", mTown)
          this.createTownWindow(TownScene, mTown);
        }
      }
    }
  }

  getCityName(id) {
    let cityDatas = this.cache.json.get('cityname_json').list
    for(let i = 0; i < cityDatas.length; i++) {
      let data = cityDatas[i]
      if(id == data.id) {
        return data.name
      }
    }

    return ""
  }

  towngoback() {
    console.log("towngoback towngoback")
    if (this.UIWin) {
      this.scene.remove(this.UIWin);
      this.UIWin = null;
    }
    if (this.townWin) {
      this.scene.remove(this.townWin);
      this.townWin = null;
    }

    this.setConvasOnEvent();

    this.registry.set("sceneName",
      {
        "name": "TownScene",
        'show_earth_view': 1,   //显示城镇地图时把地球隐藏
      });
  }

  createWindow(func) {

    var handle = 'window' + this.count++;

    var win = this.add.zone(0, 0, func.WIDTH, func.HEIGHT).setInteractive().setOrigin(0);

    var demo = new func(handle, win);

    this.input.setDraggable(win);

    win.on('drag', function (pointer, dragX, dragY) {

      this.x = dragX;
      this.y = dragY;

      demo.refresh()

    });

    this.scene.add(handle, demo, true);
  }

  createTownWindow(func, params) {
    //显示城镇地图时把地球隐藏(在这里设置好像没起到作用)
    this.registry.set("show_earth_view", { "show": false })
    let handle = 'town_win_' + this.count++;
    let win = this.add.zone(0, 0, this.game.canvas.width, this.game.canvas.height).setInteractive().setOrigin(0);
    this.townWin = new func(win, this.count, params);
    this.scene.add(handle, this.townWin, true);
  }

  changeData(parent, key, data) {
    // console.log("xxx1 changeData ==>", parent, key, data)
    // if (key == 'sceneName') {
    //   console.log("xxx2 changeData ==>", parent, key, data)
    // }
  }

  switchNavi(index) {
    if (index == 1) {
      for (let i = 0; i < this.cityLayers.length; i++) {
        this.cityLayers[i].setVisible(true);
      }
      for (let i = 0; i < this.townLayers.length; i++) {
        this.townLayers[i].setVisible(false);
      }
      // this.cityLayer.setVisible(true);
      // this.townLayer.setVisible(false);
    } else {
      for (let i = 0; i < this.cityLayers.length; i++) {
        this.cityLayers[i].setVisible(true);
      }
      for (let i = 0; i < this.townLayers.length; i++) {
        this.townLayers[i].setVisible(true);
      }
      // this.cityLayer.setVisible(false);
      // this.townLayer.setVisible(true);
    }
  }

  swithTown(param) {
    console.log("swithTown param =", param);
    if (this.UIWin) {
      this.scene.remove(this.UIWin);
      this.UIWin = null;
    }
    if (this.townWin) {
      this.scene.remove(this.townWin);
      this.townWin = null;
    }
    this.registry.set("selectTown", param)
    param.isTween = false;
    this.createTownWindow(TownScene, param);
  }
}