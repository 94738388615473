import BaseUI from "./BaseUI"

import scale_bg from '@/game/assets/dlg/scale_bg.png'
import scale_add from '@/game/assets/dlg/scale_add.png'
import scale_del from '@/game/assets/dlg/scale_del.png'

class MapZoomUI extends BaseUI {
    constructor(sceneKey, zone, posX, posY, scaleNum, onCreate) {
        super(sceneKey, zone, posX, posY, scaleNum, onCreate)
        this.naviIndex = 1;
    }

    preload() {

        super.addElement("scale_bg", BaseUI.Image, 0, 0, 1, { url: scale_bg })

        super.addElement("scale_add", BaseUI.Image, 0, 0, 2, { url: scale_add })
        super.addElement("scale_del", BaseUI.Image, 0, 43, 2, { url: scale_del })

        super.preload()
    }

    create() {
        super.create()
        let that = this;

        let addBtn = this.getElement('scale_add').com;
        addBtn.setOrigin(0.5)
        let w1 = addBtn.width * this.scaleNum;
        let h1 = addBtn.height * this.scaleNum;
        addBtn.setPosition(addBtn.x + w1 / 2, addBtn.y + h1 / 2);

        this.addModuleClickEvent('scale_add', 'pointerdown', () => {
            if (that.scale_addTween) {
                that.scale_addTween.restart()
            }
            else {
                that.scale_addTween = that.tweens.add({
                    targets: addBtn,
                    scale: 0.25,
                    ease: 'Sine.easeInOut',
                    duration: 200,
                    yoyo: true
                });
            }

            that.scene.get("WorldScene").events.emit("mapZoomAdd");
        });

        let delBtn = this.getElement('scale_del').com;
        delBtn.setOrigin(0.5)
        let w2 = delBtn.width * this.scaleNum;
        let h2 = delBtn.height * this.scaleNum;
        delBtn.setPosition(delBtn.x + w2 / 2, delBtn.y + h2 / 2);

        this.addModuleClickEvent('scale_del', 'pointerdown', () => {
            if (that.scale_delTween) {
                that.scale_delTween.restart()
            }
            else {
                that.scale_delTween = that.tweens.add({
                    targets: delBtn,
                    scale: 0.25,
                    ease: 'Sine.easeInOut',
                    duration: 200,
                    yoyo: true
                });
            }

            that.scene.get("WorldScene").events.emit("mapZoomDel");
        });
    }

    show() {

    }

    hide() {
        super.setActive(false)
    }
}

export default MapZoomUI