import BaseUI from "./BaseUI"

import arrow from '@/game/assets/dlg/arrow.png'
import select_bg from '@/game/assets/dlg/select_bg.png'
import normal_bg from '@/game/assets/dlg/normal_bg.png'


const SelectColor = '#0000FF'
const NormalColor = '#000000'

class CityLandNavi extends BaseUI {
    constructor(sceneKey, zone, posX, posY, scaleNum, onCreate) {
        super(sceneKey, zone, posX, posY, scaleNum, onCreate)
        this.naviIndex = 1
    }

    preload() {

        let landOffX = 133

        super.addElement("normal_bg", BaseUI.Image, 0, 0, 1, { url: normal_bg })

        super.addElement("citySelectBg", BaseUI.Image, 0, 0, 2, { url: select_bg })
        super.addElement("townSelectBg", BaseUI.Image, landOffX, 0, 2, { url: select_bg })

        super.addElement("cityArrow", BaseUI.Image, 10, 10, 2, { url: arrow })
        super.addElement("townArrow", BaseUI.Image, landOffX + 10, 10, 2, { url: arrow })

        super.addElement("cityText", BaseUI.Text, 25, 9, 3, { text: "City Location", fontColor: SelectColor, fontSize: 40 })
        super.addElement("townText", BaseUI.Text, landOffX + 25, 9, 3, { text: "Land Location", fontColor: NormalColor, fontSize: 40 })

        super.setBG("normal_bg")

        super.preload()
    }

    create() {
        super.create()
        let that = this

        this.getElement('townSelectBg').com.setFlip(true, false)
        this.switchNavi(this.naviIndex)

        this.addModuleClickEvent('citySelectBg', 'pointerdown', () => {
            that.switchNavi(1)
        })

        this.addModuleClickEvent('townSelectBg', 'pointerdown', () => {
            that.switchNavi(2)
        })
    }

    show() {
        super.setActive(true)
    }

    hide() {
        super.setActive(false)
    }

    switchNavi(index) {
        this.scene.get("WorldScene").events.emit("switchNavi", index)

        if (index == 1) {
            this.getElement('cityText').com.setColor(SelectColor)
            this.setElementActive('cityArrow', true)
            this.getElement('citySelectBg').com.alpha = 1

            this.getElement('townText').com.setColor(NormalColor)
            this.setElementActive('townArrow', false)
            this.getElement('townSelectBg').com.alpha = 0.000001

        } else {
            this.getElement('cityText').com.setColor(NormalColor)
            this.setElementActive('cityArrow', false)
            this.getElement('citySelectBg').com.alpha = 0.000001

            this.getElement('townText').com.setColor(SelectColor)
            this.setElementActive('townArrow', true)
            this.getElement('townSelectBg').com.alpha = 1
        }
    }
}

export default CityLandNavi