import Phaser from 'phaser'
import BootScene from '@/game/scenes/BootScene'
import WorldScene from '@/game/scenes/WorldScene'
import PlayScene from '@/game/scenes/PlayScene'
import TownScene from '@/game/scenes/TownScene'

function launch(containerId, width, height) {
  return new Phaser.Game({
    type: Phaser.AUTO,
    width: width,
    height: height,
    parent: containerId,
    dom: {
      createContainer: true
    },
    physics: {
      default: 'arcade',
    },
    scene: [BootScene, WorldScene, PlayScene, TownScene]
  })
}

export default launch
export { launch }
