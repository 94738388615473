import BaseUI from "./BaseUI"

import btn_center_buy from '@/game/assets/btn_center_buy.png'


class HomeUI extends BaseUI {
    constructor(sceneKey, zone, posX, posY, scaleNum, onCreate, onClick) {
        super(sceneKey, zone, posX, posY, scaleNum, onCreate)
        this.onClick = onClick
    }

    preload() {
        super.addElement("btn_center_buy", BaseUI.Image, 0, 0, 1, { url: btn_center_buy })
        super.setBG("btn_center_buy")

        super.preload()
    }

    create() {
        super.create()

        super.addModuleClickEvent("btn_center_buy", "pointerdown", this.onClick)
    }

    show() {
        super.setActive(true)
    }

    hide() {
        super.setActive(false)
    }
}

export default HomeUI