import { Scene } from 'phaser'
import town_map_1 from '@/game/assets/towns/town_tpl_1.json'
import town_map_2 from '@/game/assets/towns/town_tpl_2.json'
import town_map_3 from '@/game/assets/towns/town_tpl_3.json'
import town_map_4 from '@/game/assets/towns/town_tpl_4.json'
import town_map_5 from '@/game/assets/towns/town_tpl_5.json'

import town_bg_1 from '@/game/assets/towns/town_1.png'
import town_bg_2 from '@/game/assets/towns/town_2.png'
import town_bg_3 from '@/game/assets/towns/town_3.png'
import town_bg_4 from '@/game/assets/towns/town_4.png'
import town_bg_5 from '@/game/assets/towns/town_5.png'

import house_png from '@/game/assets/towns/house.png'
import gray_house_png from '@/game/assets/towns/gray_house.png'
// import landtips from '@/game/assets/html/landtips.html'

import cityname_json from '@/game/assets/cityname.json'

// 弹窗的资源
import arrow from '@/game/assets/dlg/arrow.png'
import btn_close from '@/game/assets/dlg/btn_close.png'
import select_bg from '@/game/assets/dlg/select_bg.png'
import normal_bg from '@/game/assets/dlg/normal_bg.png'
import land_bg from '@/game/assets/dlg/land_bg.png'
import dlg_bg from '@/game/assets/dlg/dlg_bg.png'
import city_info from '@/game/assets/dlg/city_info.png'
import land_info from '@/game/assets/dlg/land_info.png'

import BaseUI from './ui/BaseUI';
import CityTips from './ui/CityTips';

export default class TownScene extends Scene {
  constructor(parent, index, params) {
    super({ key: 'TownScene' + index })

    this.parent = parent;
    this.params = params;
    this.count = 0;

    this.map_towns = {
      'town_tpl_1.json': { 'bg': town_bg_1, 'bg_key': 'bg_key_1', 'map': town_map_1, 'map_key': "map_1", 'bg_tileset_name': 'town_1' },
      'town_tpl_2.json': { 'bg': town_bg_2, 'bg_key': 'bg_key_2', 'map': town_map_2, 'map_key': "map_2", 'bg_tileset_name': 'town_2' },
      'town_tpl_3.json': { 'bg': town_bg_3, 'bg_key': 'bg_key_3', 'map': town_map_3, 'map_key': "map_3", 'bg_tileset_name': 'town_3' },
      'town_tpl_4.json': { 'bg': town_bg_4, 'bg_key': 'bg_key_4', 'map': town_map_4, 'map_key': "map_4", 'bg_tileset_name': 'town_4' },
      'town_tpl_5.json': { 'bg': town_bg_5, 'bg_key': 'bg_key_5', 'map': town_map_5, 'map_key': "map_5", 'bg_tileset_name': 'town_5' },
    }

    this.bgLayer = null;
    this.landLayer = null;

    this.marker = null;
    this.selectX = 0;
    this.selectY = 0;
    this.lands = null;   //该城镇所有的土地信息
    this.fitZoom = 1;
    this.landTips = null;
    this.grayHouseList = {}
    this.grayHouseCount = 0;
    this.landjsonKey = ''

    // console.log('TownScene.map_towns =', this.map_towns);
    if (parent) {
      // console.log('TownScene.parent = ', parent, params);
    }

    this.townList = [];
    this.cityLevel = '';
    this.cityNameMap = {};
  }

  preload() {
    console.log("url-param = ", window.location)
    this.requestParam = this.registry.get("selectTown");
    this.startIndex = this.requestParam.startIndex
    console.log('TownScene preload!!, requestParam = ', this.requestParam);
    this.cityLevel = this.requestParam.cityLevel
    this.townList = this.requestParam.townList

    this.townInfo = this.townList[this.startIndex - 1];  //默认显示第1个城镇

    this.load.json('cityname_json', cityname_json)

    for (let key in this.map_towns) {
      let town = this.map_towns[key]

      this.load.image(town.bg_key, town.bg);
      this.load.tilemapTiledJSON(town.map_key, town.map);
    }

    this.mapTown = this.map_towns[this.townInfo.url];

    this.load.image('town_land', house_png);

    // let url = MCM_WEB_API + '/assets/landjson/' + this.townInfo.id + '.json'
    this.reqlandjson = require('@/game/assets/landjson/' + this.townInfo.id + '.json');
    // console.log("reqlandjson =", this.reqlandjson);
    this.landjsonKey = 'landjson' + this.townInfo.id;
    // this.load.json(this.landjsonKey, reqlandjson);
    console.log('landjsonKey =', this.landjsonKey);

    this.load.image('arrow', arrow)
    this.load.image('btn_close', btn_close)
    this.load.image('select_bg', select_bg)
    this.load.image('normal_bg', normal_bg)
    this.load.image('land_bg', land_bg)
    this.load.image('dlg_bg', dlg_bg)
    this.load.image('city_info', city_info)
    this.load.image('land_info', land_info)

    for (let i = 1; i <= 4; i++) {
      this.load.image('selectBtn_' + i, require('@/game/assets/towns/selectBtn_' + i + '.png'))
      this.load.image('selectBtn_' + i + '_active', require('@/game/assets/towns/selectBtn_' + i + '_active' + '.png'))
    }

    this.load.image('gray_house', gray_house_png)

    console.log('TownScene preload end !!!');
  }

  create() {
    console.log('TownScene.create!!');
    this.registry.set("sceneName",
      {
        "name": "TownScene",
        'show_earth_view': 2,   //显示城镇地图时把地球隐藏
      });

    let that = this;
    this.isMouseDown = false;

    let cityDatas = this.cache.json.get('cityname_json').list
    for(let i = 0; i < cityDatas.length; i++) {
      let data = cityDatas[i]
      this.cityNameMap[data.id] = data.name;
    }

    this.cam = this.cameras.main;
    if (this.parent) {
      this.cameras.main.setViewport(this.parent.x, this.parent.y, this.game.canvas.width, this.game.canvas.height);
    }

    this.town_map = this.make.tilemap({ key: this.mapTown.map_key });
    let bg_tileset = this.town_map.addTilesetImage(this.mapTown.bg_tileset_name, this.mapTown.bg_key);
    let land_tileset = this.town_map.addTilesetImage('house', 'town_land');

    this.landTipsShowFlag = false

    this.bgLayer = this.town_map.createLayer('BG', bg_tileset, 0, 0);
    this.landLayer = this.town_map.createLayer('Land', land_tileset, 0, 0);
    this.bgLayer.setOrigin(0);

    //把土地等比缩放到canvas尺寸
    let cWidth = this.game.canvas.width - 80;
    let cHeight = this.game.canvas.height - 80;
    let w = cWidth / this.bgLayer.width
    let h = cHeight / this.bgLayer.height
    if (w < 1 || h < 1) {
      this.fitZoom = w < h ? w : h;
    }

    that.cam.scrollX += (this.bgLayer.width - this.game.canvas.width) / 2;
    that.cam.scrollY += (this.bgLayer.height - this.game.canvas.height) / 2;

    this.marker = this.add.graphics();
    this.marker.lineStyle(3, 0xef5b9c, 2);
    this.marker.strokeRect(0, 0, this.town_map.tileWidth, this.town_map.tileHeight);

    let landjson = this.reqlandjson; //this.cache.json.get(this.landjsonKey);

    this.landInfo = {}
    for (let i = 0; i < landjson.length; i++) {
      let land = landjson[i];
      let key = 'x_' + land.posx + '_y_' + land.posy;
      this.landInfo[key] = landjson[i];

      if (land.status != 1) {
        this.grayHouseCount += 1;
        let tile = this.town_map.getTileAt(land.posx, land.posy, false, this.landLayer);

        if (tile) {
          this.add.sprite(tile.pixelX, tile.pixelY, 'gray_house').setOrigin(0);
        }
        // this.add.sprite(land.posx * 32, land.posy * 32, 'gray_house').setOrigin(0);
      }
    }
    console.log("gHCount = ", this.grayHouseCount);

    this.game.canvas.onmousedown = function (e) {
      that.isMouseDown = true;
    };

    this.game.canvas.onmouseup = function (e) {
      that.isMouseDown = false;
    };

    this.game.canvas.ontouchstart = function (e) {
      if (e.touches.length == 1) {
        let touchData = e.touches[0]
        that.lastTouchX = touchData.clientX
        that.lastTouchY = touchData.clientY

        that.touchZoom = false
      }
      else if (e.touches.length == 2) {
        let touch1Data = e.touches[0]
        let touch2Data = e.touches[1]

        that.lastTouch1X = touch1Data.clientX
        that.lastTouch1Y = touch1Data.clientY

        that.lastTouch2X = touch2Data.clientX
        that.lastTouch2Y = touch2Data.clientY

        that.touchZoom = true
      }
    }

    this.game.canvas.ontouchmove = function (e) {
      if (that.landTipsShowFlag) return
      if (e.touches.length == 1) {
        let touchData = e.touches[0]
        let newTouchX = touchData.clientX
        let newTouchY = touchData.clientY

        if (!that.touchZoom) {
          that.cam.scrollX -= ((newTouchX - that.lastTouchX) / that.cam.zoom);
          that.cam.scrollY -= ((newTouchY - that.lastTouchY) / that.cam.zoom);
        }

        that.lastTouchX = newTouchX
        that.lastTouchY = newTouchY

        that.touchZoom = false
      } else if (e.touches.length == 2) {
        let touch1Data = e.touches[0]
        let touch2Data = e.touches[1]
        let newTouch1X = touch1Data.clientX
        let newTouch1Y = touch1Data.clientY
        let newTouch2X = touch2Data.clientX
        let newTouch2Y = touch2Data.clientY

        let newDist = Math.sqrt(Math.pow(newTouch1X - newTouch2X, 2) + Math.pow(newTouch1Y - newTouch2Y, 2))
        let oldDist = Math.sqrt(Math.pow(that.lastTouch1X - that.lastTouch2X, 2) + Math.pow(that.lastTouch1Y - that.lastTouch2Y, 2))

        that.cam.zoom += (newDist - oldDist) / (window.gameWidth / 1080 * 1000)
        that.limitCameraZoom()

        that.lastTouch1X = newTouch1X
        that.lastTouch1Y = newTouch1Y

        that.lastTouch2X = newTouch2X
        that.lastTouch2Y = newTouch2Y

        that.touchZoom = true
      }
    }

    this.game.canvas.onmousemove = function (e) {
      if (that.landTipsShowFlag) return

      if (that.isMouseDown) {
        that.cam.scrollX -= (e.movementX / that.cam.zoom);
        that.cam.scrollY -= (e.movementY / that.cam.zoom);
        that.selectX = 0;
        that.selectY = 0;
        if (that.element) {
          that.element.removeAllListeners();
          that.element.removeElement();
          that.element = null;
        }
      }
    };

    this.game.canvas.onmouseleave = function (e) {
      that.isMouseDown = false;
      that.selectX = 0;
      that.selectY = 0;
    };

    this.game.canvas.onwheel = function (e) {
      if (that.landTipsShowFlag) return

      if (e.deltaY > 0) {
        that.cam.zoom -= 0.01;
      } else if (e.deltaY < 0) {
        that.cam.zoom += 0.01;
      }

      that.limitCameraZoom()

      that.selectX = 0;
      that.selectY = 0;

      if (that.element) {
        that.element.removeAllListeners();
        that.element.removeElement();
        that.element = null;
      }

    }

    if (this.requestParam.isTween) {
      this.tweens.add({
        targets: that.cam,
        zoom: that.fitZoom,
        duration: 500,
        ease: 'Linear',
        onComplete: (twn, params) => {
          that.initFlag = false;
        },
      });
    } else {
      that.cam.zoom = that.fitZoom;
    }

    window.NaviWin.hide()
    let closeBtn = this.add.image(this.bgLayer.width - 45, -35, "btn_close").setOrigin(0).setInteractive()
    closeBtn.on('pointerup', function (pointer) {
      if (that.landTipsShowFlag) return

      BaseUI.remove(that, "LandTips")

      that.scene.get("WorldScene").events.emit("towngoback");
      window.NaviWin.show()
    });

    for (let i = 1; i <= this.townList.length; i++) {
      if (i == this.startIndex) {
        let selectBtn = this.add.image(this.bgLayer.width - 51 - 55 * (this.townList.length - i), this.bgLayer.height - 50, "selectBtn_" + i + "_active").setOrigin(0)
      }
      else {
        let selectBtn = this.add.image(this.bgLayer.width - 51 - 55 * (this.townList.length - i), this.bgLayer.height - 50, "selectBtn_" + i).setOrigin(0).setInteractive()
        selectBtn.on('pointerdown', function (pointer) {
          if (that.landTipsShowFlag) return
          if (that.requestParam.startIndex == i) {
            console.log("startIndex =", that.requestParam.startIndex, i)
            return
          }
          BaseUI.remove(that, "LandTips")
          that.requestParam.startIndex = i
          that.scene.get("WorldScene").events.emit("swithTown", that.requestParam)
        });
      }
    }
  }

  limitCameraZoom() {
    if (this.cam.zoom < 0.02) {
      this.cam.zoom = 0.02
    } else if (this.cam.zoom > 2) {
      this.cam.zoom = 2
    }
  }

  update() {
    if (this.landTipsShowFlag) return

    var worldPoint = this.input.activePointer.positionToCamera(this.cam);

    // Rounds down to nearest tile
    var pointerTileX = this.town_map.worldToTileX(worldPoint.x);
    var pointerTileY = this.town_map.worldToTileY(worldPoint.y);

    var tile = this.town_map.getTileAt(pointerTileX, pointerTileY, false, this.landLayer);
    if (tile) {

      this.marker.x = this.town_map.tileToWorldX(pointerTileX);
      this.marker.y = this.town_map.tileToWorldY(pointerTileY);

      if (this.input.manager.activePointer.isDown) {
        this.selectX = tile.x;
        this.selectY = tile.y;
      } else {
        if (tile.x == this.selectX && tile.y == this.selectY) {
          let key = 'x_' + tile.x + '_y_' + tile.y;
          this.selectX = 0;
          this.selectY = 0;

          let deltaX = this.marker.x - this.cam.scrollX;
          let deltaY = this.marker.y - this.cam.scrollY;

          console.log("deltaX,Y=", deltaX, deltaY, this.cam.scrollX, this.cam.scrollY, this.marker.x, this.marker.y, worldPoint.x, worldPoint.y)

          this.showLandTips2(tile)
        }
      }
    } else {
      // let bgTile = this.town_map.getTileAt(pointerTileX, pointerTileY, false, this.bgLayer);
      // if (bgTile) {
      //   this.marker.x = this.town_map.tileToWorldX(pointerTileX);
      //   this.marker.y = this.town_map.tileToWorldY(pointerTileY);
      // }
    }
  }

  renderer() {
    console.log('render')
  }

  showLandTips(tile) {
    let pos = this.input.activePointer.positionToCamera(this.cam);
    let key = 'x_' + tile.x + '_y_' + tile.y;
    let land = this.landInfo[key];

    if (this.element) {
      this.element.removeAllListeners();
      this.element.removeElement();
      this.element = null;
    }

    if (!land) {
      return
    }

    this.element = this.add.dom(pos.x, pos.y).createFromCache('landtips');
    console.log("element", this.element)
    this.element.setPerspective(800);
    this.element.addListener('click');
    this.element.on('click', function (event) {
      if (event.target.name === 'buyButton') {
        console.log('hell buyButton!!!', tile);
      }
      console.log("element click", event);
    });

    let level = "B级"
    if (land.level == 1) {
      level = "S级"
    } else if (land.level == 2) {
      level = "A级"
    }

    console.log("预售土地:", land)
    let land_no = this.element.getChildByID("land_no")
    if (land_no) {
      land_no.outerHTML = land.id
    }
    let land_pos = this.element.getChildByID("land_pos")
    if (land_pos) {
      land_pos.outerHTML = '(' + land.posx + ', ' + land.posy + ')'
    }
    let land_level = this.element.getChildByID("land_level")
    if (land_level) {
      land_level.outerHTML = level
    }
    let land_status = this.element.getChildByID("land_status")
    if (land_status) {
      land_status.outerHTML = (land.status == 1) ? "预售中" : "系统保留";   //已售卖
    }
    let buyButton = this.element.getChildByID("buyButton")
    if (buyButton) {
      if (land.status != 1) {
        buyButton.enabled = false;
      }
    }

    this.element.scale = 0.01
    this.tweens.add({
      targets: this.element,
      scale: 1,
      duration: 300,
      ease: 'Power3'
    });

    console.log("TODO:显示土地简介及buy按钮", tile)
  }

  showLandTips2(tile) {
    let key = 'x_' + tile.x + '_y_' + tile.y;
    let land = this.landInfo[key];
    if (!land || land.status != 1) {
      return
    }
    // console.log("showLandTips2 land=", land);

    let starContent = 'Titan' //"泰坦星"
    let infoConetent = land.cityid + ' City, ' + land.townid + 'Town'
    let cityName = this.cityNameMap[land.cityid]
    if (cityName) {
      infoConetent = cityName + ' , ' + land.townid + 'Town'
    }
 
    let cityLevel = this.cityLevel

    let landLevel = "B"
    if (land.level == 1) {
      landLevel = "S"
    } else if (land.level == 2) {
      landLevel = "A"
    }

    let landId = land.id
    let landPos = 'X:' + land.posx + '/Y:' + land.posy;
    let landUrl = 'https://static-download2.metacitym.com' + land.imageurl

    if (!this.landTips) {
      this.landTips = BaseUI.create(this, CityTips, "LandTips", 300, 150, 0.5, (self) => { self.setAlignPos() }, () => {
        this.time.delayedCall(300, () => {
          this.landTipsShowFlag = false
        })
      });
    }

    let name = ""
    // let landIDStr = String(landId)
    // for (let i = 0; i < landIDStr.length; i++) {
    //   if (i < landIDStr.length) {
    //     let rnd = Number(landIDStr[i])
    //     if (!isNaN(rnd) && rnd < landIDStr.length) {
    //       name += landIDStr[rnd]
    //     }
    //     else {
    //       name += rnd
    //     }
    //   }
    //   else {
    //     name += i
    //   }
    // }

    this.landTips.show(starContent, name, infoConetent, cityLevel, landId, landLevel, landPos, landUrl);
    this.landTipsShowFlag = true
  }
}